import React, { useState, useEffect } from "react";
import { BillerLogo } from "components/ezpay/common/biller-logo";
import Modal from "react-modal";
import Sheet from "react-modal-sheet";
import { PaymentAmountModal } from "../../common/payment-amount-modal";
import { CashModal } from "../../common/retail-cash-flow/cash-modal";
import { PaymentMethodModal } from "../../common/payment-method-modal";
import { RetailerView as RetailerModal } from "../../common/retail-cash-flow/retailer-view";
import XButton from "assets/icons/x_button.svg";
import CircleInfo from "assets/icons/info_icon_solid.svg";
import triangleWarning from "assets/icons/triangle_exclamation.svg";
import {
  useCurrencyFormatter,
  useDateFormatter,
  usePaymentHistory,
  useRetailer,
} from "hooks";
import { BankAccountModal } from "../../common/bank-account-modal";
import { CreditDebitModal, isExpiryCardDateValid } from "../../common/credit-debit-modal";
import { getBiller } from "selectors";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import {
  Banner,
  BannerProps,
  Button,
  ButtonRow,
  Tooltip,
  TooltipEnums,
} from "kubra-ux-forge";
import { Donation } from "../../common/donation";
import { ICharityState } from "types/charity";
import { useNavigate, useOutletContext } from "react-router";
import { ISession } from "types/session";
import {
  getBankAccount,
  getCreditDebitCard,
  getPaymentIntent,
  getWallet,
} from "selectors/paymentIntent";
import { CardType } from "kubra-ux-forge/dist/foundation/CreditCardLogos/CreditCardLogo";
import {
  ReceiptInfoModal,
  IReceiptInfo,
} from "../../common/receipt-info-modal";
import { ACTION } from "actions/paymentIntentActions";
import { postPrepareIntent } from "api/IamApi/entities/ezpay";
import { getI18n, useTranslation } from "react-i18next";
import {
  BALANCE_NAMESPACE,
  BANK_ACCCOUNT_MODAL,
  COMMON_NAMESPACE,
  PAYMENT_METHOD_MODAL_NAMESPACE,
  PAYMENT_RECEIPT_NAMESPACE,
} from "constants/i18n-namespaces";
import { ContactAndTocFooter } from "components/ezpay/common/contact-and-toc-footer";
import { PageLoader } from "components/ezpay/common/page-loader";
import { setAppLoading } from "actions/appStateActions";
import { DigitalPaymentData } from "types/digitalPaymentData";
import { IDigitalWallet } from "types/paymentIntent";
import { PaymentIntentPrepareRequest } from "types/Api/Payment/paymentIntentPrepareRequest";
import useWallet from "hooks/useWallet";
import FundingOption, { getCardNetworkByShortCode, translateBankAccountType } from "constants/FundingOption";
import { IDonation } from "types/Api/Payment/Donation";
import _ from "lodash";
import { PaymentDateModal } from "../../common/payment-date-modal";
import { IPaymentOptionsProperties } from "types/biller";
import { ManagePaymentMethodsModal } from "../../common/manage-payment-methods-modal";
import moment from "moment";
import routing from "routing";
import { mapCharityToCharityState, mapUiLanguageToSessionLanguage, phoneFormat } from "utilities/helper";
import { customStyles } from "constants/customStyles";
import { isMobile } from "react-device-detect";
import { ReviewChargesModal } from "../../common/review-charges-modal";
import IamApi from "api/IamApi";
import { DashboardSkeleton } from "components/ezpay/common/dashboard-skeleton";
import { ErrorBalance } from "../error-balance";


//components for displaying the various views within payment method modal
const methodComponents = {
  PaymentMethodModal,
  CashModal,
  RetailerModal,
  BankAccountModal,
  CreditDebitModal,
};
export type MethodComponentsOptions = keyof typeof methodComponents;

//components for displaying the various views within payment intent modal
const paymentIntentComponents = { ReceiptInfoModal, ReviewChargesModal };
export type PaymentIntentComponentsOptions =
  keyof typeof paymentIntentComponents;

export const castToCardType = (
  cardTypeString: string
): CardType | undefined => {
  try {
    const cardType: CardType = cardTypeString.toLowerCase() as CardType;
    return cardType;
  } catch (error) {
    return undefined;
  }
};

enum PaymentAllowed {
  NotDefined,
  AnyAmount,
  BilledAmountOrLess, 
  BilledAmountOnly,
  NoPaymentAllowed
}

export const BalanceFrame = (props: { isMobile: any }) => {
  const { t } = useTranslation(BALANCE_NAMESPACE);
  const { handleSubmit } = useForm();
  const maxRecentPayments = 3;
  const { account, logout, login } = useOutletContext<ISession>();
  const [amountModalIsOpen, setAmountModalIsOpen] = useState(false);
  const [methodModalIsOpen, setMethodModalIsOpen] = useState(false);
  const [dateModalIsOpen, setDateModalIsOpen] = useState(false);
  const [receiptInfoModalIsOpen, setReceiptInfoModalIsOpen] = useState(false);
  const [managePaymentMethodModalIsOpen, setManagePaymentMethodModalIsOpen] =
    useState(false);
  const [currentPaymentAmount, setCurrentPaymentAmount] = useState(0.0);
  const [finalAmountDue, setFinalAmountDue] = useState(0.0);
  const [accountNumber, setAccountNumber] = useState("");
  const [finalDueDate, setFinalDueDate] = useState("");
  const [activeMethodComponent, setActiveMethodComponent] =
    useState<MethodComponentsOptions>("PaymentMethodModal");
  const [activePaymentIntentComponent, setActivePaymentIntentComponent] =
    useState<PaymentIntentComponentsOptions>("ReceiptInfoModal");
  const [displayPaymentIntentBack, setDisplayPaymentIntentBack] =
    useState(false);
  const [receiptInfoHeader, setReceiptInfoHeader] = useState(t("receipt-information"));
  const [bannerState, setBannerState] = useState<BannerProps>({
    active: false,
    variant: "info",
    messageLabel: "",
  });
  const [displayMethodBack, setDisplayMethodBack] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState("");
  const [totalErrorMessage, setTotalErrorMessage] = useState("");
  const [paymentMethodError, setPaymentMethodError] = useState("");
 
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isErrorFetchingBalance, setIsErrorFetchingBalance] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    loadConfigWalletPayments: true,
    fetchBalanceAndAccountMessages: true,
    setAccountBalanceAndOverpayment: true,
    setWalletAsDefaultPaymentMethod: true,
    
  });
  
  const [paymentAllowedState, setPaymentAllowedState] = useState<PaymentAllowed>(PaymentAllowed.NotDefined);

  const currencyFormatter = useCurrencyFormatter();
  const dateFormatter = useDateFormatter();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const finalBiller = useSelector(getBiller);

  const { loadBillerConfiguration, getBillerConfiguration } = useRetailer();
  const { loadWalletItems, getWalletItems } = useWallet();
  const {
    loadRecentPayments,
    loadUpcomingPayment,
    getUpcomingPayment,
    getRecentPayments,
  } = usePaymentHistory();

  const walletItems = getWalletItems();
  const bankAccount = useSelector(getBankAccount);
  const creditDebitCard = useSelector(getCreditDebitCard);
  const wallet = useSelector(getWallet);
  const billerConfiguration = getBillerConfiguration();
  const upcomingPayment = getUpcomingPayment();
  const recentPayments = getRecentPayments();

  const CurrentMethodComponent: any = methodComponents[activeMethodComponent];
  const CurrentPaymentIntentComponent: any =
    paymentIntentComponents[activePaymentIntentComponent];
  const paymentIntentState = useSelector(getPaymentIntent);
  const todayDateString = dateFormatter.shortFormat(new Date(), "en-US");

  const setAmountModalIsOpenToTrue = () => {
    setAmountModalIsOpen(true);
  };

  const setMethodModalIsOpenToTrue = () => {
    setDisplayMethodBack(false);
    setMethodModalIsOpen(true);
    setBannerState((prevState) => ({ ...prevState, active: false }));
  };

  const setReceiptInfoModalIsOpenToTrue = () => {
    setReceiptInfoModalIsOpen(true);
  };

  const setReceiptInfoModalIsOpenToFalse = () => {
    setReceiptInfoModalIsOpen(false);
  };

  const setAmountModalIsOpenToFalse = () => {
    setAmountModalIsOpen(false);
    setPaymentErrorMessage("");
    setBannerState((prevState) => ({ ...prevState, active: false }));
  };

  const setMethodModalIsOpenToFalse = () => {
    setMethodModalIsOpen(false);
    setActiveMethodComponent("PaymentMethodModal");
    setPaymentMethodError("");
  };

  const updateCurrentPaymentAmount = (amount: number) => {
    setCurrentPaymentAmount(amount);
    setPaymentErrorMessage("");
    setTotalErrorMessage("");
    setBannerState((prev) => ({
      ...prev,
      active: false,
      variant: "error",
      messageLabel: "",
    }));
    setAmountModalIsOpenToFalse();
  };

  const updateLoadingState = (key: string, value: boolean) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

 

  const handleMethodChange = (method: MethodComponentsOptions) => {
    setActiveMethodComponent(method);
    if (method === "PaymentMethodModal") {
      setDisplayMethodBack(false);
    } else setDisplayMethodBack(true);
  };

  const handlePaymentIntentChange = (
    method: PaymentIntentComponentsOptions
  ) => {
    setActivePaymentIntentComponent(method);
    if (method === "ReceiptInfoModal") {
      setDisplayPaymentIntentBack(false);
    } else setDisplayPaymentIntentBack(true);
  };

  const paymentDateClicked = () => {
    if (futureDateDisabled) return;

    setDateModalIsOpen(true);
  };

  // get required values from subresources
  const imageResources: any = finalBiller?.properties?.find((element: any) => {
    return element.name === "ImageResources";
  });

  const externalLinks: any = finalBiller?.properties?.find((element: any) => {
    return element.name === "ExternalLinks";
  });

  const contactUs: any = finalBiller?.properties?.find((element: any) => {
    return element.name === "InstanceConfiguration";
  });

  const paymentSettings = finalBiller?.properties?.find(
    (element) => element.name === "PaymentOptions"
  )?.properties as IPaymentOptionsProperties;

  // Get Charities
  const charities = account.charities?.filter(
    (ch) => ch.code != null && ch.name != null
  );

  const [charitiesState, setCharitiesState] = useState(
    mapCharityToCharityState(charities)
  );

  const handleCharitySelection = (charityCode: string, selected: boolean) => {
    updateCharity(charityCode, { selected });
  };

  const handleCharityAmountChange = (charityCode: string, amount: number) => {
    updateCharity(charityCode, { amount });
  };

  const updateCharity = (
    charityCode: string,
    updateValue: Partial<ICharityState>
  ) => {
    if (updateValue.selected !== undefined && !updateValue.selected) {
      updateValue.amount = 0;
    }

    const newCharitiesState = charitiesState.map((charityState) => {
      return charityState.charity.code !== charityCode
        ? charityState
        : {
          ...charityState,
          ...updateValue,
        };
    });
    setCharitiesState(newCharitiesState);
    dispatch({ type: ACTION.ADD_CHARITIES, payload: newCharitiesState });
  };

  // Add charities amount
  const charitiesTotal = charitiesState
    .filter((ch) => ch.selected)
    .reduce((amount, charity) => amount + charity.amount, 0);

  const parseDueDate = (dueDate: string) => {
    return dueDate ? dateFormatter.shortFormat(new Date(moment(dueDate).utc().toString()), "en-US") : "";
  };

  let paymentMethodLabel = t("select-one");

  if (bankAccount != null) {
    paymentMethodLabel =
      translateBankAccountType(bankAccount.accountType, t) +
      " *" +
      bankAccount.bankAccountNumber.substring(
        bankAccount.bankAccountNumber.length - 4
      );
  } else if (creditDebitCard != null) {
    paymentMethodLabel =
    getCardNetworkByShortCode(creditDebitCard.cardType) +
      " *" +
      creditDebitCard.cardNo.substring(creditDebitCard.cardNo.length - 4);
  } else if (wallet != null) {
    let label = "";
    if (wallet.fundingOption === FundingOption.Card) {
      label = getCardNetworkByShortCode(wallet.presentableMOPCode) + " " + wallet.maskedNumber;
      if (!isExpiryCardDateValid(wallet.expiry)) {
        setBannerState({
          active: true,
          variant: "warning",
          messageLabel: t("card-expired-warning", { card: label }),
          withClose: true,
          withIcon: true,
          withHyperlink: true,
          hyperlink: () => setManagePaymentMethodModalIsOpen(true),
          hyperlinkText: t("manage-payment-methods-button", {
            ns: PAYMENT_METHOD_MODAL_NAMESPACE,
          }),
        });
        // setWarningMessage(t("card-expired-warning", { card: label }));
        // reset wallet
        dispatch({ type: ACTION.ADD_WALLET, payload: null });
        dispatch({
          type: ACTION.ADD_PAYMENT_METHOD,
          payload: "",
        });
      }
    } else {
      // translate bank account type
      label =
        translateBankAccountType(wallet.presentableMOPCode, t) +
        " " +
        wallet.maskedNumber;
    }
    paymentMethodLabel = label;
  }

  const getSelectedCharities = (): IDonation[] | undefined => {
    if (
      paymentIntentState.charities == null ||
      paymentIntentState.charities?.length == 0
    ) {
      return undefined;
    }
    return paymentIntentState.charities
      .filter((c) => c.selected && c.amount > 0)
      .map((selectedCharity) => ({
        charityCode: selectedCharity.charity.code,
        amount: selectedCharity.amount,
      }));
  };

  const getPrepareRequest = (): PaymentIntentPrepareRequest | null => {
    let selectedFsOption = "";
    if (bankAccount != null) {
      selectedFsOption = FundingOption.BankAccount;
    } else if (creditDebitCard != null) {
      selectedFsOption = FundingOption.Card;
    } else if (wallet != null) {
      selectedFsOption = FundingOption.Wallet;
    }

    if (selectedFsOption === FundingOption.Card) {
      return {
        paymentAmount: paymentIntentState.paymentAmount,
        donations: getSelectedCharities(),
        fundingOption: selectedFsOption,
        card: {
          card: creditDebitCard.cardNo.replace(/\s/g, ""),
          cvc: creditDebitCard.securityCode,
          cardType: getCardNetworkByShortCode(creditDebitCard.cardType),
          expiry: creditDebitCard.cardExpiry,
          zip: creditDebitCard.zipCode,
          holderName:
            creditDebitCard.firstName + " " + creditDebitCard.lastName,
          saveToWallet: creditDebitCard.saveToWallet,
        },
        paymentDate: paymentIntentState.paymentDate,
      };
    } else if (selectedFsOption === FundingOption.BankAccount) {
      return {
        paymentAmount: paymentIntentState.paymentAmount,
        donations: getSelectedCharities(),
        fundingOption: selectedFsOption,
        bank: {
          bankNumber: bankAccount.bankAccountNumber,
          routingNumber: bankAccount.routingNumber,
          accountType: bankAccount.accountType,
          holderName: bankAccount.accountOwnerName,
          saveToWallet: bankAccount.saveToWallet,
        },
        paymentDate: paymentIntentState.paymentDate,
      };
    } else if (selectedFsOption === FundingOption.Wallet) {
      return {
        paymentAmount: paymentIntentState.paymentAmount,
        donations: getSelectedCharities(),
        fundingOption: selectedFsOption,
        wallet: {
          fundingSourceId: wallet.fundingSourceId,
          type: wallet.fundingOption,
        },
        paymentDate: paymentIntentState.paymentDate,
      };
    }
    return null;
  };

  const onSubmit = (data: any) => {
    // @ts-ignore
    //buttonRef.current.setLoadingToTrue();
    //setLoading(true);
    // - set up next step in ez-pay flow
  };

  const updatePaymentIntentState = (
    paymentIntentId: string,
    accountNumber: string,
    finalAmountDue: number,
    finalDueDate: string
  ) => {
    dispatch({ type: ACTION.ADD_INTENT_ID, payload: paymentIntentId });
    dispatch({ type: ACTION.ADD_AMOUNT_DUE, payload: finalAmountDue });
    dispatch({ type: ACTION.ADD_ACCOUNT_NO, payload: accountNumber });
    dispatch({ type: ACTION.ADD_DUE_DATE, payload: finalDueDate });
    dispatch({
      type: ACTION.ADD_PAYMENT_AMOUNT,
      payload: finalAmountDue,
    });

    // if user changes the date, update the date here
    const paymentDate = moment().startOf("day").toDate();
    dispatch({
      type: ACTION.ADD_PAYMENT_DATE,
      payload: paymentDate,
    });
  };

  const reviewDigitalPayment = (paymentData: DigitalPaymentData) => {
    setMethodModalIsOpenToFalse();

    // clean up charities if it has, not applicable for digital wallet payment
    dispatch({ type: ACTION.ADD_CHARITIES, payload: [] });
    setCharitiesState([]);

    // update payment intent
    const digitalWallet: IDigitalWallet = {
      cardType: paymentData.cardType,
      paymentToken: paymentData.paymentToken,
    };
    dispatch({ type: ACTION.ADD_DIGITAL_WALLET, payload: digitalWallet });
    const receiptInfo: IReceiptInfo = {
      mobileNumber: paymentData.mobileNumber ?? "",
      mobileAlert: false,
      email: paymentData.email,
      emailAlert: false,
      alertEnabled: false,
    };

    dispatch({ type: ACTION.ADD_RECEIPTINFO, payload: receiptInfo });
    dispatch({
      type: ACTION.ADD_PAYMENT_METHOD,
      payload: paymentData.paymentMethod,
    });
    dispatch({ type: ACTION.ADD_SERVICE_FEE, payload: paymentData.serviceFee });

    handlePaymentIntentChange("ReviewChargesModal");
    setDisplayPaymentIntentBack(false);
    setReceiptInfoModalIsOpen(true);
  };

  const resetSelectedPaymentMethod = () => {
    paymentIntentState.creditDebitCard = null;
    paymentIntentState.bankAccount = null;
    paymentIntentState.wallet = undefined;
    paymentIntentState.digitalWallet = undefined;
  }

  const verifyPaymentAmountForDigitalWallet = () => {
    const isValidAmount = isAmountPayable();
    if (!isValidAmount.isValid) {
      setMethodModalIsOpenToFalse();
      return  { isValid: false };
    }
    return  { isValid: true };
  }

  const handlePrepareIntentErrorForDigitalWallet = (err: any, prepareRequest: PaymentIntentPrepareRequest) => {
    resetSelectedPaymentMethod();
    handlePrepareIntentErrors(err, prepareRequest);
    setMethodModalIsOpenToFalse();
  }

  const isAmountPayable = () => {
    const minAmount = 1;
    if (paymentIntentState.paymentAmount === 0) {
      setPaymentErrorMessage(t("overpayment-enabled-no-amount-error"));
      setErrorMessageInBanner(t("payment-amount-invalid"));
      return  { isValid: false };
    }

    if (
      paymentSettings?.allowPartialPayment === "true" &&
      paymentIntentState.paymentAmount < minAmount
    ) {
      setPaymentErrorMessage(t("invalid-min-payment-amount"));
      setErrorMessageInBanner(t("invalid-minimum-payment-banner"));
      return { isValid: false };
    }

    if (paymentAllowedState === PaymentAllowed.BilledAmountOrLess && currentPaymentAmount > finalAmountDue) {
      setPaymentErrorMessage(
        t("overpayment-disabled-amount-error", {
          amount: finalAmountDue,
        })
      );
      setErrorMessageInBanner(t("overpayment-disabled-banner-error"));
      return { isValid: false };
    }

    if (paymentAllowedState === PaymentAllowed.BilledAmountOnly && (currentPaymentAmount !== finalAmountDue)) {
      setPaymentErrorMessage(t("over-and-partial-payment-disabled-banner-error"));
      setErrorMessageInBanner(t("over-and-partial-payment-disabled-banner-error"));
      return { isValid: false };
    }

     // if no error, reset the error message
     setBannerState((prev) => ({
      ...prev,
      active: false,
      variant: "error",
      messageLabel: "",
    }));
    return { isValid: true };

  }

  const getSelectedPaymentMethod = (prepareRequest: PaymentIntentPrepareRequest) => {

    if (prepareRequest.fundingOption === FundingOption.Card) {
      return "Card";
    } else if (prepareRequest.fundingOption === FundingOption.BankAccount) {
      return "BankAccount";
    } else if (prepareRequest.fundingOption === FundingOption.Wallet) {
      if (prepareRequest.wallet?.type === "Card") {
        return "Card";
      }
      else if (prepareRequest.wallet?.type === "BankAccount") {
        return "BankAccount";
      }

    }
    // return selected digital wallet
    return prepareRequest.fundingOption;
  }

  const setErrorMessageInBanner = (message: string) => {

    setBannerState((prev) => ({
      ...prev,
      active: true,
      variant: "error",
      messageLabel: message,
      withClose: true,
      withHyperlink: false,
      withIcon: true,
    }));
  }

  const handlePrepareIntentErrors = (err: any, prepareRequest: PaymentIntentPrepareRequest) => {
    const selectedPaymentMethod = getSelectedPaymentMethod(prepareRequest);
    switch (err.errorMessageCode) {

      // if payment method is invalid, code will be 24/25/26/45
      case "24":
      case "25":
      case "26":
      case "45":
        setErrorMessageInBanner(t("payment-method-invalid"));
        return;

      // if payment velocity error, code will be 48/49
      case "48":
      case "49":
        setErrorMessageInBanner(t("payment-velocity-exceeded-error"));
        return;

      // if payment amount is invalid, code will be 30/31
      case "30":
      case "31":
        if (
          paymentSettings?.allowPartialPayment === "false" &&
          paymentIntentState.paymentAmount > err.errorMessageValue
        ) {

          if (
            selectedPaymentMethod === "Card" ||
            selectedPaymentMethod === "GooglePay" ||
            selectedPaymentMethod === "ApplePay" ||
            selectedPaymentMethod === "PayPal"
          ) {
            setErrorMessageInBanner(t("invalid-amount-partial-payments-card-banner", {
              method: selectedPaymentMethod === "Card" ? t("card"): selectedPaymentMethod,
              phone: phoneFormat(contactUs.properties.phone),
            }))
            return;
          } else {
            setErrorMessageInBanner(t("invalid-amount-partial-payments-bank-banner", {
              phone: phoneFormat(contactUs.properties.phone),
            }))
            return;
          }

        } else if (paymentIntentState.charities != undefined) {
          if (err.errorMessageType === "MinAmount") {
            if (
              selectedPaymentMethod === "Card" ||
              selectedPaymentMethod === "GooglePay" ||
              selectedPaymentMethod === "ApplePay" ||
              selectedPaymentMethod === "PayPal"
            ) {
              setErrorMessageInBanner(t("invalid-min-amount-card-banner", {
                amount: err.errorMessageValue,
                method: selectedPaymentMethod === "Card" ? t("card"): selectedPaymentMethod,
              }));
              setTotalErrorMessage(
                t("payment-total-error", { amount: err.errorMessageValue })
              );
              return;
            } else {
              setErrorMessageInBanner(t("invalid-min-amount-bank-banner", {
                amount: err.errorMessageValue,
              }));
              setTotalErrorMessage(
                t("payment-total-error", { amount: err.errorMessageValue })
              );
              return;
            }
          } else if (
            selectedPaymentMethod === "Card" ||
            selectedPaymentMethod === "GooglePay" ||
            selectedPaymentMethod === "ApplePay" ||
            selectedPaymentMethod === "PayPal"
          ) {
            setErrorMessageInBanner(t("invalid-max-amount-card-banner", {
              amount: err.errorMessageValue,
              method: selectedPaymentMethod === "Card" ? t("card"): selectedPaymentMethod,
            }));
            setTotalErrorMessage(
              t("payment-total-error", { amount: err.errorMessageValue })
            );
            return;
          } else {
            setErrorMessageInBanner(t("invalid-max-amount-bank-banner", {
              amount: err.errorMessageValue,
            }));
            setTotalErrorMessage(
              t("payment-total-error", { amount: err.errorMessageValue })
            );
            return;
          }
        } else if (err.errorMessageType === "MinAmount") {
          if (
            selectedPaymentMethod === "Card" ||
            selectedPaymentMethod === "GooglePay" ||
            selectedPaymentMethod === "ApplePay" ||
            selectedPaymentMethod === "PayPal"
          ) {
            setErrorMessageInBanner(t("invalid-min-amount-card-banner", {
              amount: err.errorMessageValue,
              method: selectedPaymentMethod === "Card" ? t("card"): selectedPaymentMethod,
            }));
            setPaymentErrorMessage(
              t("payment-min-amount-error", {
                amount: err.errorMessageValue,
              })
            );
            return;
          } else {
            setErrorMessageInBanner(t("invalid-min-amount-bank-banner", {
              amount: err.errorMessageValue,
            }));
            setPaymentErrorMessage(
              t("payment-min-amount-error", {
                amount: err.errorMessageValue,
              })
            );
            return;
          }
        } else if (
          selectedPaymentMethod === "Card" ||
          selectedPaymentMethod === "GooglePay" ||
          selectedPaymentMethod === "ApplePay" ||
          selectedPaymentMethod === "PayPal"
        ) {
          setErrorMessageInBanner(t("invalid-max-amount-card-banner", {
            amount: err.errorMessageValue,
            method: selectedPaymentMethod === "Card" ? t("card"): selectedPaymentMethod,
          }));
          setPaymentErrorMessage(
            t("payment-max-amount-error", {
              amount: err.errorMessageValue,
            })
          );
          return;
        } else {
          setErrorMessageInBanner(t("invalid-max-amount-bank-banner", {
            amount: err.errorMessageValue,
          }));
          setPaymentErrorMessage(
            t("payment-max-amount-error", {
              amount: err.errorMessageValue,
            })
          );
          return;
        }
      default:
        setErrorMessageInBanner(t("process-payment-error"));
        return;

    }

  }

  const handleContinueClick = () => {
    // validate required fields
    
    const isAmountValid = isAmountPayable();
    if (!isAmountValid.isValid) {
      return;
    }

    if (bankAccount == null && creditDebitCard == null && wallet == null) {
      setPaymentMethodError(t("no-payment-method-selected-error"));
    }

    if (
      paymentIntentState.charities != undefined &&
      paymentIntentState.charities?.filter((c) => c.selected && c.amount == 0)
        .length > 0
    ) {
      setErrorMessageInBanner(t("donation-amount-required"));
      return;
    }

    // if no error, reset the error message
    setBannerState((prev) => ({
      ...prev,
      active: false,
      variant: "error",
      messageLabel: "",
    }));

    //Create Prepare request

    const prepareRequest = getPrepareRequest();

    if (prepareRequest === null) {
      return;
    }

    dispatch(setAppLoading(true));
    void (async () => {
      await postPrepareIntent(
        finalBiller != null ? finalBiller.clientId : "",
        paymentIntentState.intentId,
        prepareRequest
      )
        .then((res) => {
          // show ReceiptInfo page
          // Set Conveneience fee if it exists
          dispatch({
            type: ACTION.ADD_SERVICE_FEE,
            payload: Number(res.data.feeTransactionAmount),
          });
          setReceiptInfoHeader(t("receipt-information"));
          setActivePaymentIntentComponent("ReceiptInfoModal");
          setReceiptInfoModalIsOpenToTrue();
          setBannerState((prev) => ({
            ...prev,
            active: false,
            variant: "error",
            messageLabel: "",
            withClose: true,
            withHyperlink: false,
            withIcon: true,
          }));
        })
        .catch((err) => {
          handlePrepareIntentErrors(err, prepareRequest);
        });
    })().then(() => dispatch(setAppLoading(false)));
  };

  useEffect(() => {
    // Load config and wallet and recent payments
    const loadConfigWalletPayments = async () => {
      if (finalBiller !== null && finalBiller !== undefined) {
        try {
          await Promise.all([
            loadBillerConfiguration(finalBiller.clientId, account.realm),
            loadWalletItems(finalBiller.clientId),
            loadRecentPayments(
              maxRecentPayments,
              finalBiller.clientId,
              account.realm
            ),
            loadUpcomingPayment(finalBiller.clientId, account.realm),
          ]);
        } catch (error) {
          console.log('Error loading config, wallet and recent payments:', error);
        } finally {
          updateLoadingState("loadConfigWalletPayments", false);
        }
      } else {
        updateLoadingState("loadConfigWalletPayments", false);
      }
    };

    loadConfigWalletPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalBiller]);

  

  // getBalance and restriction messages
  useEffect(() => {
    const fetchBalanceAndAccountMessages = async () => {
      if (_.isEmpty(account.paymentIntentId)) {
        let response;
        try {
          response = await IamApi.ezpay.postBalance(
            account.fingerprint,
            navigator.userAgent
          );

          // get restriction messages only if messageCode or fundingRestrictionsInEffect is 
          // not empty in balance response
          if (response?.data?.balance?.messageCodes?.length > 0 ||
            response?.data?.balance?.fundingRestrictionsInEffect?.length > 0) {
            response = await IamApi.ezpay.postAccountMessages();
          }
        } catch (error) {
          setIsErrorFetchingBalance(true);
          console.log('Error fetching balance or account messages:', error);
        } finally {
          if (response) {
            login(response.data);
          }
          updateLoadingState("fetchBalanceAndAccountMessages", false);
        }
      } else {
        updateLoadingState("fetchBalanceAndAccountMessages", false);
      }
    };
    fetchBalanceAndAccountMessages();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);


  useEffect(() => {
    // Set Account Balance and Overpayment status
    if (!isDataLoading) {
      const accountNumber = account?.accountNumber;
      setAccountNumber(accountNumber);
      const balance = account.balance;
      if (balance) {
        const finalAmountDue = balance?.currentAmountDue ?? 0.0;
        const finalDueDate = parseDueDate(balance?.dueDate ?? "");
        setCurrentPaymentAmount(finalAmountDue);
        setFinalAmountDue(finalAmountDue);
        setFinalDueDate(finalDueDate);
        updatePaymentIntentState(
          account.paymentIntentId,
          account.accountNumber,
          finalAmountDue,
          finalDueDate
          );
          updatePaymentAllowedState(finalAmountDue);
      }
      updateLoadingState("setAccountBalanceAndOverpayment", false);
    } else {
      updateLoadingState("setAccountBalanceAndOverpayment", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoading, account, finalBiller]);

  useEffect(() => {
    // if wallets available, set preferred Wallet as Default Payment Method
    const setLastUsedWalletAsDefaultPaymentMethod = () => {
      if (walletItems.length > 0) {
        const walletItem = walletItems.find((_) => _.isPreferred);
        const isRestricted = account?.balance?.fundingRestrictionsInEffect?.includes(walletItem?.fundingOption ?? "");
        if (walletItem !== undefined && !isRestricted) {
          dispatch({ type: ACTION.ADD_CREDIT_DEBIT_CARD, payload: null });
          dispatch({ type: ACTION.ADD_BANK_ACCOUNT, payload: null });
          dispatch({ type: ACTION.ADD_WALLET, payload: walletItem });
          dispatch({
            type: ACTION.ADD_PAYMENT_METHOD,
            payload: FundingOption.Wallet,
          });
        }
      } else if (wallet) {
        dispatch({ type: ACTION.ADD_WALLET, payload: null });
        dispatch({
          type: ACTION.ADD_PAYMENT_METHOD,
          payload: "",
        });
      }
      updateLoadingState("setWalletAsDefaultPaymentMethod", false);
    };

    if (loadingStates.fetchBalanceAndAccountMessages === false) {
      setLastUsedWalletAsDefaultPaymentMethod();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletItems, account]);

  useEffect(() => {
    // scroll to top when banner shows up
    if (bannerState.active) {
      // scrolls to top in Safari
      document.body.scrollTop = 0;
      // scrolls to top in Chrome, Firefox, IE, and Opera
      document.documentElement.scrollTop = 0;
    }

  }, [bannerState.active]);

   // Monitoring loadingStates and updating isDataLoading accordingly
   useEffect(() => {
    const allDone = Object.values(loadingStates).every((state) => state === false);
    if (allDone) {
      setIsDataLoading(false);
    }
  }, [loadingStates]);

  let maxScheduledDays = -1;
  if (
    paymentSettings !== undefined &&
    paymentSettings.scheduledPaymentMaximumDays !== undefined
  ) {
    maxScheduledDays = parseInt(paymentSettings.scheduledPaymentMaximumDays);
  }
  let maxDueDate = moment().startOf("day").toDate();

  if (maxScheduledDays !== undefined && maxScheduledDays >= 0) {
    maxDueDate = moment(finalDueDate, "MM-DD-YY").startOf("day").toDate();
    maxDueDate.setDate(maxDueDate.getDate() + maxScheduledDays);
  }

  const futureDateDisabled =
    !_.isEmpty(upcomingPayment) ||
    moment(maxDueDate).isBefore(moment().startOf("day").toDate()) ||
    maxScheduledDays < 0;

  let showDateDisabledMessage = false;

  if (
    maxScheduledDays >= 0 &&
    moment(maxDueDate).isBefore(moment().startOf("day").toDate())
  ) {
    showDateDisabledMessage = true;
  } else if (!props.isMobile && maxScheduledDays < 0) {
    showDateDisabledMessage = true;
  }

  // Update payment allowed state

  const updatePaymentAllowedState = (currentFinalAmountDue: number) => {

    if (paymentSettings?.allowPartialPayment === "true") {
      //overpayment allowed
      if (paymentSettings?.allowOverPayment === "true") {
        // any amount can be paid
        if (currentFinalAmountDue > 0) {
          setPaymentAllowedState(PaymentAllowed.AnyAmount);
          return;
        }
        else {
          // any amount can be paid, also show banner on zero balance or credit
          setPaymentAllowedState(PaymentAllowed.AnyAmount);
          setBannerState({
            active: true,
            variant: "info",
            messageLabel: t("no-payment-due-overpayment-allowed"),
            withClose: true,
            withHyperlink: false,
            withIcon: true,
          });
        }

      }
      // overpayment not allowed
      else {
        if (currentFinalAmountDue > 0) {
          // Billed amount or less can be paid
          setPaymentAllowedState(PaymentAllowed.BilledAmountOrLess);
        }
        else {
          // No payment allowed
          // hide the payment details form
          setPaymentAllowedState(PaymentAllowed.NoPaymentAllowed);
          setBannerState({
            active: true,
            variant: "info",
            messageLabel: t("no-payment-due-overpayment-not-allowed"),
            withClose: true,
            withHyperlink: false,
            withIcon: true,
          });
        }
      }
      
    }
    // partial payment not allowed
    // no need to check overpayment value 
    else {
      if (currentFinalAmountDue > 0) {
        // Billed amount only can be paid
        setPaymentAllowedState(PaymentAllowed.BilledAmountOnly);
      }
      else {
        // No payment allowed
        // hide the payment details form
        setPaymentAllowedState(PaymentAllowed.NoPaymentAllowed);
        setBannerState({
          active: true,
          variant: "info",
          messageLabel: t("no-payment-due-overpayment-not-allowed"),
          withClose: true,
          withHyperlink: false,
          withIcon: true,
        });
      }
    }

  }


  // Account Messages

  const i18n = getI18n();

  const defaultBannerProps: Omit<BannerProps, 'messageLabel'> = {
    active: true,
    variant: 'info',
    withClose: true,
    withHyperlink: false,
    withIcon: true,
  };

  function prepareAccountMessages(
    messages: string[]
  ): BannerProps[] {
    return messages.map((message) => ({
      ...defaultBannerProps,
      messageLabel: message,
    }));
  }

  // Filter and map account messages. make sure that the language format is 4 letter (en-US, es-US, etc)
  const filterMsgs = account.restrictionMessages != undefined ? account.restrictionMessages
    .filter((acntMessage) => acntMessage.language === mapUiLanguageToSessionLanguage(i18n.resolvedLanguage ?? 'en'))
    .map((acntMessage) => acntMessage.message) : [];

  const accountMessages = prepareAccountMessages(filterMsgs);

  // End Account Messages

  
  if (isDataLoading) {
    return (<DashboardSkeleton visible={true}></DashboardSkeleton>);
  }
  else if (isErrorFetchingBalance) {
    return (<ErrorBalance visible={true} biller={finalBiller} accountNumber={accountNumber} isMobile={isMobile} ></ErrorBalance>)
  }
  return (

    <div>
      {/* banner for generic info and error */}
      <div className="ez-pay-balance-banner">
        <Banner
          {...bannerState}
          onClose={() => setBannerState((prev) => ({ ...prev, active: false }))}
        ></Banner>

        {/* banner for account/restriction messages */}
        {accountMessages.map((banner) => (
          banner.active && (
            <Banner
              key={banner.messageLabel}
              variant={banner.variant}
              messageLabel={banner.messageLabel}
              withClose={banner.withClose}
              withIcon={banner.withIcon}
            />
          )
        ))}
      </div>
      <div className="ez-pay-balance-frame">
        <PageLoader />
        <div className="ez-pay-balance-top">

          <div className="validate-header">
            <BillerLogo
              billerLogo={imageResources?.properties?.logoURL ?? ""}
              billerUrl={contactUs?.properties?.website}
            />
          </div>

          <div className="ez-pay-balance-row">

            <div className="ez-pay-balance-content-center-amount-due-row">
              {t("amount-due")}
              <Tooltip
                direction={isMobile ? TooltipEnums.TopLeft : TooltipEnums.Right}
                content={t("amount-due-tooltip")}
                classNames="amount-due-row-tooltip"
                data-cy="amount-due-tooltip"
              />
            </div>


            <div className="ez-pay-balance-content-center">
              <div
                className="ez-pay-balance-label-large"
                data-cy="final-amount-due"
              >
                {currencyFormatter.format(finalAmountDue)}
              </div>
            </div>

            <div className="ez-pay-balance-content-center-small">
              <div className="ez-pay-balance-content-cell">
                {t("due-date")}{" "}
                <span
                  className="ez-pay-balance-cell-value"
                  data-cy="final-due-date"
                >
                  {dateFormatter.shortFormat(finalDueDate)}
                </span>
              </div>
              <div className="ez-pay-balance-content-cell">
                {t("account-number")}{" "}
                <span
                  className="ez-pay-balance-cell-value"
                  data-cy="account-number"
                >
                  {accountNumber}
                </span>
              </div>
            </div>

            <div>
              {!_.isEmpty(upcomingPayment) && (
                <>
                <div className="ez-pay-balance-content-center-small">
                  <div className="ez-pay-scheduled-payment-content-cell">
                    {t("scheduled-payment")}{" "}
                    {dateFormatter.shortFormat(
                      new Date(upcomingPayment.paymentDate)
                    )}{" "}
                    <span className="ez-pay-schedule-payment-amount-cell-value">
                      {currencyFormatter.format(upcomingPayment.totalAmount)}
                    </span>
                  </div>
                </div>
                <br/>
                </>
              )}
              {(!_.isEmpty(upcomingPayment) || recentPayments.length > 0) && (
                <div className="ez-pay-balance-content-center-small">
                  <button
                    className="ez-pay-button-link"
                    onClick={() => navigate(routing.recentPayments.index())}
                    data-cy="recent-payments"
                  >
                    {t("view-recent-payment")}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="ez-pay-balance-divider"></div>
          {(paymentAllowedState === PaymentAllowed.AnyAmount || paymentAllowedState === PaymentAllowed.BilledAmountOnly || paymentAllowedState === PaymentAllowed.BilledAmountOrLess) && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="ez-pay-balance-no-frame">
                {t("payment-details")}
              </div>
              {paymentSettings?.allowPartialPayment !== "true" ? null : (
                <div className="ez-pay-balance-button-container">
                  <ButtonRow
                    buttonLabel={
                      currentPaymentAmount !== finalAmountDue ||
                        finalAmountDue === 0
                        ? currencyFormatter.format(currentPaymentAmount)
                        : t("full-balance")
                    }
                    onClick={setAmountModalIsOpenToTrue}
                    staticLabel={`${t("amount")}`}
                    /* @ts-ignore */
                    dataCy="button-row-payment-amount"
                    data-testid="payment-amount-button-row"
                    errorText={
                      paymentErrorMessage && paymentErrorMessage.length > 0
                        ? paymentErrorMessage
                        : ""
                    }
                  />
                </div>
              )}

              <div className="ez-pay-balance-button-container">
                <ButtonRow
                  buttonLabel={paymentMethodLabel}
                  onClick={setMethodModalIsOpenToTrue}
                  staticLabel={`${t("method")}`}
                  creditCardLogoVariant={
                    creditDebitCard !== null
                      ? castToCardType(creditDebitCard.cardType.toLowerCase())
                      : wallet &&
                        (wallet.fundingOption === FundingOption.Card)
                        ? castToCardType(getCardNetworkByShortCode(wallet.presentableMOPCode).toLocaleLowerCase())
                        : undefined
                  }
                  /* @ts-ignore */
                  dataCy="buttonw-row-payment-method"
                  data-testid="method-button-row"
                  errorText={
                    bankAccount == null &&
                      creditDebitCard == null &&
                      wallet == null
                      ? paymentMethodError
                      : ""
                  }
                />
              </div>
              {maxScheduledDays >= 0 && (
                <div className="ez-pay-balance-button-container">
                  <ButtonRow
                    disabled={futureDateDisabled}
                    buttonLabel={
                      dateFormatter.shortFormat(
                        paymentIntentState.paymentDate,
                        "en-US"
                      ) === todayDateString
                        ? `${t("today", { ns: COMMON_NAMESPACE })}`
                        : dateFormatter.shortFormat(
                          paymentIntentState.paymentDate,
                          "en-US"
                        )
                    }
                    onClick={paymentDateClicked}
                    staticLabel={`${t("date", { ns: COMMON_NAMESPACE })}`}
                    /* @ts-ignore */
                    dataCy="button-row-payment-date"
                    data-testid="payment-date-button"
                  />
                </div>
              )}


              {showDateDisabledMessage && (
                <div className="ez-pay-balance-container-scheduled">
                  <div>
                    <img src={CircleInfo} alt="info icon" width={16} height={16} />
                  </div>
                  <div>{t("scheduled-payment-disabled")}</div>
                </div>
              )}
              {!_.isEmpty(upcomingPayment) && (
                <div className="ez-pay-balance-button-container">
                  <Tooltip
                    direction={TooltipEnums.TopRight}
                    content={`${t("only-one-scheduled")}`}
                    data-cy="schedule-payment-tooltip"
                  /> {" "}
                  {t("upcoming-payment-reminder", {
                    date: dateFormatter.shortFormat(
                      new Date(upcomingPayment.paymentDate)
                    ),
                    interpolation: { escapeValue: false },
                  })}
                  <button
                    onClick={() => navigate(routing.recentPayments.index())}
                    className="view-details"
                    data-cy="view-details"
                  >
                    {t("view-details")}
                  </button>
                </div>
              )}


              {charities != undefined &&
                charities != null &&
                charities.length > 0 && (
                  <div className="ez-pay-charity-container">
                    {charities?.map((charity) => (
                      <Donation
                        key={charity.code}
                        charity={charity}
                        onCharityAmountSelected={(amount: number) =>
                          handleCharityAmountChange(charity.code, amount)
                        }
                        onCharitySelected={(isSelected: boolean) =>
                          handleCharitySelection(charity.code, isSelected)
                        }
                      />
                    ))}
                  </div>
                )}
              {charities != undefined &&
                charities != null &&
                charities.length > 0 &&
                charitiesState.map(
                  (charityState) =>
                    charityState.selected &&
                    charityState.amount > 0 && (
                      <div
                        key={charityState.charity.code}
                        className="ez-pay-donation-item-parent-container"
                      >
                        <div
                          className="ez-pay-donation-item-container"
                          data-testid="charities-container"
                        >
                          <div className="ez-pay-donation-item-row">
                            <div className="ez-pay-donation-item-label">
                              {t("donation-to", {
                                name: charityState.charity.name,
                              })}
                            </div>
                            <div className="ez-pay-donation-item-amount">
                              {currencyFormatter.format(charityState.amount)}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )}

              <div className="ez-pay-payment-total-parent-container">
                <div className="ez-pay-payment-total-container">
                  <div className="ez-pay-payment-total-row">
                    <div className="ez-pay-payment-total-label">
                      {t("payment-total")}
                    </div>
                    <div
                      className={
                        paymentErrorMessage
                          ? "ez-pay-payment-total-amount-error"
                          : "ez-pay-payment-total-amount"
                      }
                      data-cy="payment-total"
                    >
                      {currencyFormatter.format(
                        currentPaymentAmount + charitiesTotal
                      )}
                    </div>
                  </div>
                  {totalErrorMessage && totalErrorMessage.length > 0 && (
                    <div className="ez-pay-balance-button-container">
                      <img
                        src={triangleWarning}
                        alt={totalErrorMessage}
                        className="floatLeft"
                      />
                      <div className="balance-amount-text-error">
                        {totalErrorMessage}
                      </div>
                    </div>
                  )}
                  <div
                    className="ez-pay-payment-total-label-info"
                    data-testid="process-payment-info"
                  >
                    {t("process-payment-info")}
                  </div>
                </div>
              </div>

              <div className="ez-pay-balance-row-noframe">
                {/* @ts-ignore */}
                <Button
                  className="validate-button"
                  onClick={handleContinueClick}
                  dataCy="button-continue"
                  data-testid="continue-button"
                >
                  {t("continue", { ns: COMMON_NAMESPACE })}
                </Button>
              </div>

              {props.isMobile && (
                <Sheet
                  isOpen={amountModalIsOpen}
                  onClose={() => setAmountModalIsOpenToFalse}
                  detent="content-height"
                  disableDrag={true}
                >
                  <Sheet.Container>
                    <Sheet.Header>
                      <div className="react-modal-sheet-header">
                        <div
                          className="help-modal-header-text"
                          data-testid="select-amount-modal-header"
                        >
                          {t("select-amount")}
                        </div>
                        <button
                          className="modal-close"
                          onClick={setAmountModalIsOpenToFalse}
                          aria-label={`${t("close-modal", {
                            ns: COMMON_NAMESPACE,
                          })}`}
                          data-cy="modal-close-x-payment-amount"
                        >
                          <img
                            src={XButton}
                            alt={`${t("close-modal", {
                              ns: COMMON_NAMESPACE,
                            })}`}
                            className="modal-close-x"
                          />
                        </button>
                      </div>
                    </Sheet.Header>
                    <Sheet.Content>
                      <PaymentAmountModal
                        allowPartialPayment={
                          paymentSettings?.allowPartialPayment === "true"
                        }
                        allowOverPayment={
                          paymentSettings?.allowOverPayment === "true"
                        }
                        fullBalanceAmount={finalAmountDue}
                        updateCallBack={updateCurrentPaymentAmount}
                      />
                    </Sheet.Content>
                  </Sheet.Container>
                  <Sheet.Backdrop />
                </Sheet>
              )}
              {!props.isMobile && (
                // @ts-ignore
                <Modal
                  isOpen={amountModalIsOpen}
                  ariaHideApp={false}
                  className="modal-frame-help"
                  shouldCloseOnOverlayClick={false}
                  onRequestClose={setAmountModalIsOpenToFalse}
                  style={customStyles}
                >
                  <div className="modal-header">
                    <div
                      className="help-modal-header-text"
                      data-testid="select-amount-modal-header"
                    >
                      {t("select-amount")}
                    </div>
                    <button
                      className="modal-close"
                      onClick={setAmountModalIsOpenToFalse}
                      aria-label={`${t("close-modal", {
                        ns: COMMON_NAMESPACE,
                      })}`}
                      data-cy="modal-close-x-payment-amount"
                    >
                      <img
                        src={XButton}
                        alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
                        className="modal-close-x"
                      />
                    </button>
                  </div>
                  <PaymentAmountModal
                    allowPartialPayment={
                      paymentSettings?.allowPartialPayment === "true"
                    }
                    allowOverPayment={
                      paymentSettings?.allowOverPayment === "true"
                    }
                    fullBalanceAmount={finalAmountDue}
                    updateCallBack={updateCurrentPaymentAmount}
                  />
                </Modal>
              )}
              {props.isMobile && (
                <Sheet
                  isOpen={methodModalIsOpen}
                  onClose={() => setMethodModalIsOpenToFalse}
                  detent="content-height"
                  disableDrag={true}
                >
                  <Sheet.Container>
                    <Sheet.Header>
                      <div
                        className="react-modal-sheet-header"
                        data-testid="select-method-modal"
                      >
                        {displayMethodBack && (
                          <button
                            onClick={() => {
                              handleMethodChange("PaymentMethodModal");
                            }}
                            className="modal-close-back"
                          >
                            <FontAwesomeIcon icon={faArrowLeft} size="xl" />
                          </button>
                        )}
                        <div className="help-modal-header-text">
                          {t("select-method")}
                        </div>
                        <button
                          className="modal-close"
                          onClick={setMethodModalIsOpenToFalse}
                          aria-label={`${t("close-modal", {
                            ns: COMMON_NAMESPACE,
                          })}`}
                          data-cy="modal-close-x-payment-method"
                        >
                          <img
                            src={XButton}
                            alt={`${t("close-modal", {
                              ns: COMMON_NAMESPACE,
                            })}`}
                            className="modal-close-x"
                          />
                        </button>
                      </div>
                    </Sheet.Header>
                    <Sheet.Content style={{ overflow: "auto" }}>
                      <CurrentMethodComponent
                        billerLogo={imageResources?.properties?.logoURL}
                        flowCallback={handleMethodChange}
                        accountNumber={accountNumber}
                        realm={account.realm}
                        biller={finalBiller}
                        amount={currentPaymentAmount}
                        billerConfiguration={billerConfiguration}
                        termsUrl={externalLinks?.properties?.termsAndConditions}
                        userAccount={account}
                        closeModalCallBack={setMethodModalIsOpenToFalse}
                        reviewDigitalPayment={reviewDigitalPayment}
                        verifyPaymentAmount={verifyPaymentAmountForDigitalWallet}
                        onPrepareIntentError={handlePrepareIntentErrorForDigitalWallet}
                        showManagePaymentMethodsModal={() =>
                          setManagePaymentMethodModalIsOpen(true)
                        }
                      />
                    </Sheet.Content>
                  </Sheet.Container>
                  <Sheet.Backdrop />
                </Sheet>
              )}
              {!props.isMobile && (
                // @ts-ignore
                <Modal
                  isOpen={methodModalIsOpen}
                  ariaHideApp={false}
                  className="modal-frame-help"
                  shouldCloseOnOverlayClick={false}
                  onRequestClose={setMethodModalIsOpenToFalse}
                  style={customStyles}
                >
                  <div
                    className="modal-header-with-back-button"
                    data-testid="select-method-modal"
                  >
                    {displayMethodBack && (
                      <button
                        onClick={() => {
                          handleMethodChange("PaymentMethodModal");
                        }}
                        className="modal-close-back"
                      >
                        <FontAwesomeIcon icon={faArrowLeft} size="xl" />
                      </button>
                    )}
                    <div className="help-modal-header-text">
                    { activeMethodComponent === "RetailerModal" ? t("cash-method") : t("select-method")} 
                    </div>
                    <button
                      className="modal-close"
                      onClick={setMethodModalIsOpenToFalse}
                      aria-label={`${t("close-modal", {
                        ns: COMMON_NAMESPACE,
                      })}`}
                      data-cy="modal-close-x--payment-method"
                    >
                      <img
                        src={XButton}
                        alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
                        className="modal-close-x"
                      />
                    </button>
                  </div>
                  <CurrentMethodComponent
                    billerLogo={imageResources?.properties?.logoURL}
                    flowCallback={handleMethodChange}
                    accountNumber={accountNumber}
                    biller={finalBiller}
                    amount={currentPaymentAmount}
                    billerConfiguration={billerConfiguration}
                    termsUrl={externalLinks?.properties?.termsAndConditions}
                    userAccount={account}
                    closeModalCallBack={setMethodModalIsOpenToFalse}
                    reviewDigitalPayment={reviewDigitalPayment}
                    verifyPaymentAmount={verifyPaymentAmountForDigitalWallet}
                    onPrepareIntentError={handlePrepareIntentErrorForDigitalWallet}
                    showManagePaymentMethodsModal={() =>
                      setManagePaymentMethodModalIsOpen(true)
                    }
                  />
                </Modal>
              )}
              {/* new receipt modal */}
              {props.isMobile && (
                <Sheet
                  isOpen={receiptInfoModalIsOpen}
                  onClose={() => setReceiptInfoModalIsOpenToFalse}
                  detent="content-height"
                  disableDrag={true}
                >
                  <Sheet.Container>
                    <Sheet.Header>
                    <div className="modal-header-with-back-button">
                        {displayPaymentIntentBack && (
                          <button
                            onClick={() => {
                              handlePaymentIntentChange("ReceiptInfoModal");
                            }}
                            className="modal-close-back"
                          >
                            <FontAwesomeIcon icon={faArrowLeft} size="xl" />
                          </button>
                        )}
                        <div className="help-modal-header-text">
                          {receiptInfoHeader}
                        </div>
                        <button
                          className="modal-close"
                          onClick={setReceiptInfoModalIsOpenToFalse}
                          aria-label={`${t("close-modal", {
                            ns: COMMON_NAMESPACE,
                          })}`}
                          data-cy="modal-close-x-receipt"
                        >
                          <img
                            src={XButton}
                            alt={`${t("close-modal", {
                              ns: COMMON_NAMESPACE,
                            })}`}
                            className="modal-close-x"
                          />
                        </button>
                      </div>
                    </Sheet.Header>
                    <Sheet.Content>
                      <CurrentPaymentIntentComponent
                        flowCallback={handlePaymentIntentChange}
                        closeModalCallBack={setReceiptInfoModalIsOpenToFalse}
                        showPaymentMethodModalCallback={() => {
                          setReceiptInfoModalIsOpenToFalse();
                          setMethodModalIsOpenToTrue();
                        }}
                        isMobile={props.isMobile}
                        setDisplayPaymentIntentBack = {setDisplayPaymentIntentBack}
                        setReceiptInfoHeader = {setReceiptInfoHeader}
                      />
                    </Sheet.Content>
                  </Sheet.Container>
                  <Sheet.Backdrop />
                </Sheet>
              )}
              {!props.isMobile && (
                // @ts-ignore
                <Modal
                  isOpen={receiptInfoModalIsOpen}
                  ariaHideApp={false}
                  className="modal-frame-help"
                  shouldCloseOnOverlayClick={false}
                  onRequestClose={setReceiptInfoModalIsOpenToFalse}
                  style={customStyles}
                >
                  <div className="modal-header-with-back-button">
                    {displayPaymentIntentBack && (
                      <button
                        onClick={() => {
                          handlePaymentIntentChange("ReceiptInfoModal");
                        }}
                        className="modal-close-back"
                      >
                        <FontAwesomeIcon icon={faArrowLeft} size="xl" />
                      </button>
                    )}
                    <div className="help-modal-header-text">
                      {receiptInfoHeader}
                    </div>
                    <button
                      className="modal-close"
                      onClick={setReceiptInfoModalIsOpenToFalse}
                      aria-label={`${t("close-modal", {
                        ns: COMMON_NAMESPACE,
                      })}`}
                      data-cy="modal-close-x-receipt"
                    >
                      <img
                        src={XButton}
                        alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
                        className="modal-close-x"
                      />
                    </button>
                  </div>
                  <CurrentPaymentIntentComponent
                    flowCallback={handlePaymentIntentChange}
                    closeModalCallBack={setReceiptInfoModalIsOpenToFalse}
                    showPaymentMethodModalCallback={() => {
                      setReceiptInfoModalIsOpenToFalse();
                      setMethodModalIsOpenToTrue();
                    }}
                    isMobile={props.isMobile}
                    setDisplayPaymentIntentBack = {setDisplayPaymentIntentBack}
                    setReceiptInfoHeader = {setReceiptInfoHeader}
                  />
                </Modal>
              )}
              {/* new receipt modal end */}
              <ManagePaymentMethodsModal
                isOpen={managePaymentMethodModalIsOpen}
                onClose={() => setManagePaymentMethodModalIsOpen(false)}
              ></ManagePaymentMethodsModal>
              <PaymentDateModal
                isMobile = {isMobile}
                dueDate={new Date(finalDueDate)}
                daysAfter={maxScheduledDays < 0 ? 0 : maxScheduledDays}
                isOpen={dateModalIsOpen}
                onClose={() => setDateModalIsOpen(false)}
                biller={finalBiller}
              ></PaymentDateModal>
            </form>
          )}
          {( (paymentAllowedState === PaymentAllowed.NoPaymentAllowed) && !isDataLoading) && (
            <div className="ez-pay-balance-row-noframe">
              <Button
                type="button"
                className="validate-button"
                onClick={logout}
                dataCy="button-validate"
              >
                {t("change-account", {
                  ns: PAYMENT_RECEIPT_NAMESPACE,
                  name: finalBiller?.billerName ?? "",
                })}
              </Button>
            </div>
          )}
          <ContactAndTocFooter biller={finalBiller} isMobile={isMobile} />
        </div>
      </div>
    </div>
  );
};
export default BalanceFrame;