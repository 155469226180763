import { IAccount } from 'types/account';
import { IBiller } from 'types/biller';
import { IBalance } from 'types/balance';
import { LIVE, SANDBOX } from 'constants/realm';


export const account: IAccount = {
  language: "en",
  streetAddress1: "123 Sycamore Steet",
  streetAddress2: "suite #404",
  locality: "Tempe",
  region: "AZ",
  postalCode: "85200",
  country: "US",
  accountNumber: '123',
  fsTypes: [],
  fundingOptions: [],
  charities: [],
  channel: '',
  paymentIntentId: "",
  balance: {
    currentAmountDue: 0,
    dueDate: '',
    lastUpdatedDate: '',
    currentCharges: 0,
    balanceForward: 0,
    minimumAmountDue: 0,
    billedAmount: 0,
    messageCodes: [],
    fundingRestrictionsInEffect: [],
    lastUpdatedAtSourceDate: '',
    amountDueAfterDueDate: 0
  },
  realm: SANDBOX,
  paypalConfiguration: {
    merchantAccountId: "",
    merchantId: "",
    braintreeToken: "",
    clientId: ""
  },
  googlePayConfiguration: {
    merchantId: "",
    merchantName: "",
    publicKey: ""
  },
  fingerprint: "12345",
  initiator: {
    userEmail: "user@kubra.com"
  },
  restrictionMessages: [],
  validation: {
    accountNumber: "123",
    uniqueQualifier: null,
    mailingAddress: {
      streetAddress1: "1234 Sycamore Steet",
      streetAddress2: "suite #404",
      locality: "Tempe",
      region: "AZ",
      postalCode: "85200",
      country: "US"
    },
    accountType: "Residential",
    businessUnitCode: "ABC",
    isPrePay: false,
    accountId: "112233~",
    namespaceId: "93c72551-1644-4593-a3ee-79b935d07ffc"
  },
  processedPayment:null
};

export const accountCritical: IAccount = {
  language: "en",
  streetAddress1: "123 Sycamore Steet",
  streetAddress2: "suite #404",
  locality: "Tempe",
  region: "AZ",
  postalCode: "85200",
  country: "US",
  accountNumber: '123',
  fsTypes: [],
  fundingOptions: [],
  charities: [],
  channel: '',
  paymentIntentId: "",
  balance: {
    currentAmountDue: 0,
    dueDate: '',
    lastUpdatedDate: '',
    currentCharges: 0,
    balanceForward: 0,
    minimumAmountDue: 0,
    billedAmount: 0,
    messageCodes: [],
    fundingRestrictionsInEffect: [],
    lastUpdatedAtSourceDate: '',
    amountDueAfterDueDate: 0
  },
  realm: LIVE,
  paypalConfiguration: {
    merchantAccountId: "",
    merchantId: "",
    braintreeToken: "",
    clientId: ""
  },
  googlePayConfiguration: {
    merchantId: "",
    merchantName: "",
    publicKey: ""
  },
  fingerprint: "12345",
  initiator: {
    userEmail: "user@kubra.com"
  },
  restrictionMessages: [],
  validation: {
    accountNumber: "123",
    uniqueQualifier: null,
    mailingAddress: {
      streetAddress1: "1234 Sycamore Steet",
      streetAddress2: "suite #404",
      locality: "Tempe",
      region: "AZ",
      postalCode: "85200",
      country: "US"
    },
    accountType: "Residential",
    businessUnitCode: "ABC",
    isPrePay: false,
    accountId: "112233~",
    namespaceId: "93c72551-1644-4593-a3ee-79b935d07ffc"
  },
  processedPayment:null,
};

export const biller: IBiller = {
  billerName: "ABCEnergy-Utility-Company",
  clientId: "a8c2b204-1563-4a9a-9581-72f0cf71e5ef",
  instanceId: "a2232e82-9bab-4090-a73c-f25229b3408b",
  snapshotId: "027b05da-49a5-44e8-a0e9-97916b79fcfa",
  realm: "LIVE",
  accountPlaceholder: "000-00000-00",
  vcodePlaceholder: "A1A 1A1",
  validationFields: [
    {
      name: "Account",
      label: [
        {
            "language": "en-US",
            "value": "Account number"
        },
        {
            "language": "es-US",
            "value": "Número de cuenta"
        },
        {
            "language": "fr-CA",
            "value": "Numéro de compte"
        }
    ],
      validation: ""
    }
  ],
  properties: [
    {
      name: "PaymentOptions",
      properties: {
        allowPartialPayment: "true",
        maxPaymentAmount: "1500",
        minPaymentAmount: "10",
        allowCashPayment: "true",
        allowOverPayment: "true",
        scheduledPaymentMaximumDays: "0"
      }
    },
    {
      name: "ImageResources",
      properties: {
        logoURL: "https://kubra.io/static/images/t1t7Q6-abc_energy.png",
        billSampleURL: "https://kubra.io/static/images/DVuVQQ-SDGE Logo 1.png"
      }
    },
    {
      name: "ExternalLinks",
      properties: {
        termsAndConditions: "https://kubra.com"
      }
    },
    {
      name: "InstanceConfiguration",
      properties: {
        phone: "9056242220",
        email: "jacky.huang@kubra.com",
        street: "5050 Tomken Road",
        city: "Mississauga",
        state: "ON",
        zipCode: "12345",
        website: "https://kubra.com"
      }
    }
  ]
};

export const balance: IBalance =
{
  currentAmountDue: 125.5,
  dueDate: '2022-11-02 1:20:56 PM',
  lastUpdatedDate: '2022-10-08 1:20:56 PM',
  currentCharges: 95.68,
  balanceForward: 13.25,
  minimumAmountDue: 13.25,
  billedAmount: 0,
  messageCodes: [],
  fundingRestrictionsInEffect: [],
  lastUpdatedAtSourceDate: '',
  amountDueAfterDueDate: 0
};
